import { CartBaseController } from "./cart_base_controller";

export default class extends CartBaseController {
  static targets = ["form", "username"];

  connect() {
    this.focusout_event = this._focusout_event.bind(this);

    if (!this.hasUsernameTarget) return;
    if (!this.hasFormTarget) return;

    this.formTarget.addEventListener("focusout", this.focusout_event);
  }

  disconnect() {
    this.formTarget.removeEventListener("focusout", this.focusout_event);
  }

  _focusout_event(event) {
    if (!this.formTarget.checkValidity()) {
      this.formTarget.classList.add("was-validated");
      return;
    }

    this.formTarget.classList.remove("was-validated");

    const username = this.usernameTarget.value.trim();
    if (username.length === 0) return;

    this.email = username;
  }
}
