import { CartBaseController } from "./cart_base_controller";

/*
 * Renders the order table.  All products are stored on localStorage, so
 * we just fetch that information and render the cart contents using
 * Liquid.
 */
export default class extends CartBaseController {
  static targets = ["cart", "subtotal", "itemCount"];

  async connect() {
    const products = this.products;
    const site = window.site;

    this.render({ products, site });
    this.subtotalUpdate();
    this.itemCountUpdate();

    this.storage_event = this._storage_event.bind(this);
    this.cart_subtotal_update_event =
      this._cart_subtotal_update_event.bind(this);

    window.addEventListener("storage", this.storage_event);
    window.addEventListener(
      "cart:subtotal:update",
      this.cart_subtotal_update_event
    );
  }

  disconnect() {
    window.removeEventListener("storage", this.storage_event);
    window.removeEventListener(
      "cart:subtotal:update",
      this.cart_subtotal_update_event
    );
  }

  async _storage_event(event) {
    if (!event.key?.startsWith("cart:item:")) return;

    const products = this.products;
    const site = window.site;

    this.render({ products, site });
    this.subtotalUpdate();
    this.itemCountUpdate();
  }

  _cart_subtotal_update_event(event) {
    this.itemCountUpdate();
    this.subtotalUpdate();
  }

  /*
   * Download the item template and render the order
   */
  render(data = {}) {
    const template = window.templates[this.data.get("itemTemplate")];

    this.engine
      .parseAndRender(template, data)
      .then((html) => (this.cartTarget.innerHTML = html));
  }

  /*
   * Updates the subtotal
   *
   * XXX: This also updates the currency
   */
  subtotalUpdate() {
    if (!this.cart) return;

    this.subtotalTarget.innerText = this.cart.data.attributes.display_total;
  }

  itemCountUpdate() {
    if (!this.hasItemCountTarget) return;
    if (!this.cart) return;

    this.itemCountTarget.innerText = this.cart.data.attributes.item_count;
  }
}
