import { CartBaseController } from "./cart_base_controller";

/*
 * Retrieves payment methods and redirect to external checkouts
 */
export default class extends CartBaseController {
  static targets = ["form", "submit", "specialInstructions"];

  async connect() {
    const orderToken = this.token;
    const response = await this.spree.checkout.paymentMethods({ orderToken });

    this.change_event = this._change_event.bind(this);

    if (response.isFail()) {
      this.handleFailure(response);
      return;
    }

    const payment_methods = response.success().data;
    const site = window.site;
    const cart = this.cart;
    const next = { url: this.data.get("nextUrl") };
    const back = { url: this.data.get("backUrl") };

    this.render({ payment_methods, site, cart, next, back });
  }

  async render(data = {}) {
    const template = window.templates[this.data.get("template")];

    this.element.innerHTML = await this.engine.parseAndRender(template, data);

    if (!this.hasSubmitTarget) return;
    this.formTarget.elements.forEach((p) =>
      p.addEventListener("change", this.change_event)
    );
  }

  disconnect() {
    if (!this.hasSubmitTarget) return;

    this.formTarget.elements.forEach((p) =>
      p.removeEventListener("change", this.change_event)
    );
  }

  _change_event(event) {
    this.submitTarget.disabled = false;
  }

  async pay(event) {
    event.preventDefault();
    event.stopPropagation();

    this.formDisabled = true;

    const payment_method_id = this.formTarget.elements.payment_method_id.value;
    const orderToken = this.token;
    const special_instructions = this.specialInstructionsTarget.value.trim();

    // XXX: Currently SpreeClient expects us to send payment source
    // attributes as if it were a credit card.
    let response = await this.spree.checkout.orderUpdate(
      { orderToken },
      {
        order: {
          special_instructions,
          payments_attributes: [{ payment_method_id }],
          payment_source: {
            [payment_method_id]: {
              name: "Pepitx",
              month: 12,
              year: 2020,
            },
          },
        },
      }
    );

    if (response.isFail()) {
      this.handleFailure(response);
      this.formDisabled = false;
      return;
    }

    this.cart = response;

    response = await this.spree.checkout.complete({ orderToken });

    if (response.isFail()) {
      this.handleFailure(response);
      this.formDisabled = false;
      return;
    }

    this.cart = response;

    const checkoutUrls = await this.spree.sutty.getCheckoutURL({ orderToken });
    let redirectUrl = this.data.get("nextUrl");

    if (checkoutUrls.data.length > 0) redirectUrl = checkoutUrls.data[0];

    window.location = redirectUrl;
  }
}
