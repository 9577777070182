import { CartBaseController } from "./cart_base_controller";

export default class extends CartBaseController {
  static targets = ["counter"];

  connect() {
    if (!this.hasCounterTarget) {
      console.error("Missing counter target");
      return;
    }

    this.cart_count_event = this._cart_count_event.bind(this);
    this.storage_event = this._storage_event.bind(this);

    window.addEventListener("cart:counter", this.cart_count_event);
    window.addEventListener("storage", this.storage_event);

    if (!this.cart) return;

    this.counter = this.cart.data.attributes.item_count;
  }

  disconnect() {
    window.removeEventListener("cart:counter", this.cart_count_event);
    window.removeEventListener("storage", this.storage_event);
  }

  set counter(quantity) {
    this.counterTarget.innerText = quantity;
  }

  _cart_count_event(event) {
    this.counter = event.detail.item_count;
  }

  _storage_event(event) {
    if (event.key == "cart:counter") this.counter = event.newValue;
  }
}
